
import { defineComponent, onMounted, ref } from 'vue';
import axios from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import JwtService from '@/core/services/JwtService';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';

export default defineComponent({
	name: 'cms-media-add',
	components: {},
	setup() {
		const formRef = ref<null | HTMLFormElement>(null);
		const addCustomerModalRef = ref<null | HTMLElement>(null);
		const loading = ref<boolean>(false);
		const imageArray = ref();

		const formData = ref({
			name: '',
			file: ''
		});

		const rules = ref({
			name: [
				{
					required: true,
					message: 'Name is required',
					trigger: 'change'
				}
			],
			file: [
				{
					required: true,
					message: 'Image File is required',
					trigger: 'change'
				}
			]
		});

		const submit = () => {
			if (!formRef.value) {
				return;
			}

			formRef.value.validate(valid => {
				if (valid) {
					loading.value = true;

					const imageData = new FormData();
					imageData.append('file', imageArray.value, imageArray.value.name);
					axios
						.post(`CmsMedia/?name=${formData.value.name}`, imageData, {
							headers: {
								Authorization: `Bearer ${JwtService.getToken()}`,
								'Content-Type': `multipart/form-data;`
							}
						})
						.then(({ data }) => {
							console.log('create cms media response', data);
							loading.value = false;
							Swal.fire({
								timer: 1500,
								text: 'Form has been successfully submitted!',
								icon: 'success',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							}).then(() => {
								(document.getElementById('formRef') as HTMLFormElement).reset();
							});
						})
						.catch(({ response }) => {
							console.log('create CMS Media error', response);
							Swal.fire({
								text: 'Sorry, looks like there are some errors detected, please try again.',
								icon: 'error',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							});
						});
				} else {
					Swal.fire({
						text: 'Sorry, looks like there are some errors detected, please try again.',
						icon: 'error',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
					return false;
				}
			});
		};

		const onChangeImage = event => {
			imageArray.value = event.target.files[0];
		};
		// const getMediaDetails = () => {
		// 	ApiService.get(`CmsMedia/${route.params.id}`)
		// 		.then(({ data }) => {
		// 			mediaDetails.value = data;
		// 		})
		// 		.catch(({ response }) => {
		// 			console.log('error', response);
		// 		});
		// };

		onMounted(() => {
			setCurrentPageBreadcrumbs('Add CMS Media', ['CMS Media']);
			// getMediaDetails();
		});

		return {
			// mediaDetails,
			// getMediaDetails
			formData,
			rules,
			submit,
			formRef,
			loading,
			addCustomerModalRef,
			onChangeImage
		};
	}
});
